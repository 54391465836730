import { useLocation } from "react-router-dom";
import PostList from "../components/PostList";
import ShowPageProfile from "../components/ShowPageProfile";
import { getCommunityInformation } from "../api";
import Warn from "../components/Warn";
import NotFoundPage from "./NotFoundPage";

function CommunityPage() {
  const location = useLocation();
  const communitySlug = location.pathname.split("/")[2];
  const { title } = getCommunityInformation(communitySlug);
  if (title === "error") {
    return (
      <NotFoundPage
        variant="big"
        title="존재하지 않는 커뮤니티입니다"
        description="올바른 주소가 맞는지 다시 한 번 확인해 주세요"
      />
    );
  }

  return (
    <>
      <ShowPageProfile title={title} />
      <PostList isCommunity={true} keyword={communitySlug} />
    </>
  );
}

export default CommunityPage;
