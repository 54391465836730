import upIcon from "../assets/icon--up.svg";
import downIcon from "../assets/icon--down.svg";
import commmentIcon from "../assets/icon--comment.svg";
import shareIcon from "../assets/icon--share.svg";
import { Link } from "react-router-dom";
import styles from "./ShowPost.module.css";
import ShowPostProfile from "./ShowPostProfile";
import { memo } from "react";

const RecommendCount = memo(function RecommendCount({ recommended }) {
  return (
    <div className={styles.recommended}>
      <div className={styles.imgBox}>
        <img className={styles.upIcon} src={upIcon} alt="추천" />
      </div>
      <p>{recommended}</p>
      <div className={styles.imgBox}>
        <img className={styles.downIcon} src={downIcon} alt="비추천" />
      </div>
    </div>
  );
});

export function ShowPost({ value, showCommunity = true }) {
  const {
    id,
    title,
    content,
    writer,
    affiliation,
    recommended,
    createdAt,
    comment,
  } = value;
  const profile = { writer, affiliation };

  return (
    <>
      <ShowPostProfile
        showCommunity={showCommunity}
        profile={profile}
        time={createdAt}
      />
      <div className={styles.texts}>
        <Link to={`/nest/${affiliation}/post/${id}`}>
          <h1>{title}</h1>
        </Link>
        <p className={styles.post}>{content}</p>
      </div>
      <div className={styles.info}>
        <RecommendCount recommended={recommended} />
        <div className={styles.commented}>
          <img src={commmentIcon} alt="댓글수" />
          <p>{comment.length}</p>
        </div>
        <div className={styles.share}>
          <img src={shareIcon} alt="공유하기" />
        </div>
      </div>
    </>
  );
}

export default ShowPost;
