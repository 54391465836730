import { Link } from "react-router-dom";
import Warn from "../components/Warn";
import styles from "./NotFoundPage.module.css";

function NotFoundPage({ variant, title, description }) {
  return (
    <>
      <Warn variant={variant} title={title} description={description} />
      <div className={styles.link}>
        <Link to="/">홈으로 가기</Link>
      </div>
    </>
  );
}

export default NotFoundPage;
