import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import PostListPage from "./pages/PostListPage";
import CommunityPage from "./pages/CommunityPage";
import PostPage from "./pages/PostPage";
import CalenderPage from "./pages/CalenderPage";
import CouncelPage from "./pages/CouncelPage";
import SignInPage from "./pages/SignInPage";
import NotFoundPage from "./pages/NotFoundPage";

function Main() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<PostListPage />} />
          <Route path="search" element={<PostListPage />} />
          <Route path="nest">
            <Route path=":communitySlug">
              <Route index element={<CommunityPage />} />
              <Route path="post">
                <Route index element={<NotFoundPage />} />
                <Route path=":postId" element={<PostPage />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route path="calender" element={<CalenderPage />} />
          <Route path="councel" element={<CouncelPage />} />
          <Route path="signin" element={<SignInPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Main;
