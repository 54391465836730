// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowPageProfile_background__e8Whw {
  width: 60vw;
  height: 200px;
  margin-top: 20px;
  padding: 20px 40px;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
}

.ShowPageProfile_pfImg__\\+KPPk {
  width: 75px;
  height: 75px;
  border: 5px solid #afafaf;
  border-radius: 70px;
  margin-right: 10px;
}

.ShowPageProfile_name__9t75D {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/components/ShowPageProfile.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".background {\n  width: 60vw;\n  height: 200px;\n  margin-top: 20px;\n  padding: 20px 40px;\n  border-radius: 10px;\n  display: flex;\n  align-items: flex-start;\n}\n\n.pfImg {\n  width: 75px;\n  height: 75px;\n  border: 5px solid #afafaf;\n  border-radius: 70px;\n  margin-right: 10px;\n}\n\n.name {\n  margin-top: 10px;\n  font-size: 20px;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `ShowPageProfile_background__e8Whw`,
	"pfImg": `ShowPageProfile_pfImg__+KPPk`,
	"name": `ShowPageProfile_name__9t75D`
};
export default ___CSS_LOADER_EXPORT___;
