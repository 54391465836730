// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/icon--up-hover.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/icon--down-hover.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowPost_post__K413X {
  white-space: pre-wrap;
}

.ShowPost_info__gTL7D {
  height: 30px;
}

.ShowPost_info__gTL7D > * {
  background-color: rgb(220, 220, 220);
  width: -moz-fit-content;
  width: fit-content;
  height: 30px;
  border-radius: 15px;
  float: left;
}

.ShowPost_info__gTL7D > *:not(.ShowPost_recommended__8vTYs):hover {
  background-color: rgb(200, 200, 200);
}

.ShowPost_recommended__8vTYs,
.ShowPost_commented__Uw2OZ {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.ShowPost_recommended__8vTYs > p {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 3px;
  margin: 0;
}

.ShowPost_imgBox__LBOPB {
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.ShowPost_imgBox__LBOPB:hover {
  background-color: rgb(200, 200, 200);
}

.ShowPost_upIcon__4MEzp:hover {
  content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.ShowPost_downIcon__uDgPi:hover {
  content: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.ShowPost_commented__Uw2OZ > p {
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 10px;
  margin: 0;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/ShowPost.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,uBAAkB;EAAlB,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,oCAAoC;AACtC;;AAEA;;EAEE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,cAAc;EACd,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,gDAA0C;AAC5C;;AAEA;EACE,gDAA4C;AAC9C;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,mBAAmB;EACnB,SAAS;EACT,kBAAkB;AACpB","sourcesContent":[".post {\n  white-space: pre-wrap;\n}\n\n.info {\n  height: 30px;\n}\n\n.info > * {\n  background-color: rgb(220, 220, 220);\n  width: fit-content;\n  height: 30px;\n  border-radius: 15px;\n  float: left;\n}\n\n.info > *:not(.recommended):hover {\n  background-color: rgb(200, 200, 200);\n}\n\n.recommended,\n.commented {\n  display: flex;\n  align-items: center;\n  margin-right: 15px;\n}\n\n.recommended > p {\n  width: fit-content;\n  padding: 0 3px;\n  margin: 0;\n}\n\n.imgBox {\n  width: 30px;\n  height: 30px;\n  border-radius: 15px;\n}\n\n.imgBox:hover {\n  background-color: rgb(200, 200, 200);\n}\n\n.upIcon:hover {\n  content: url(../assets/icon--up-hover.svg);\n}\n\n.downIcon:hover {\n  content: url(../assets/icon--down-hover.svg);\n}\n\n.commented > p {\n  width: fit-content;\n  padding-right: 10px;\n  margin: 0;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post": `ShowPost_post__K413X`,
	"info": `ShowPost_info__gTL7D`,
	"recommended": `ShowPost_recommended__8vTYs`,
	"commented": `ShowPost_commented__Uw2OZ`,
	"imgBox": `ShowPost_imgBox__LBOPB`,
	"upIcon": `ShowPost_upIcon__4MEzp`,
	"downIcon": `ShowPost_downIcon__uDgPi`
};
export default ___CSS_LOADER_EXPORT___;
