import { Link } from "react-router-dom";
import Container from "./Container";
import UserMenu from "./UserMenu";
import logoImg from "../assets/logoWithName.svg";
import styles from "./Nav.module.css";
import SearchBar from "./SearchBar";

function Nav() {
  return (
    <div className={styles.nav}>
      <Container className={styles.container}>
        <Link to="/">
          <img className={styles.image} src={logoImg} alt="Codethat Logo" />
        </Link>
        <SearchBar className={styles.searchBar} />
        <UserMenu />
      </Container>
    </div>
  );
}

export default Nav;
