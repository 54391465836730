import { useSearchParams } from "../contexts/SearchContext";
import PostList from "../components/PostList";

function PostListPage() {
  const search = useSearchParams();

  return <PostList keyword={search?.keyword} />;
}

export default PostListPage;
