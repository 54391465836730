// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RightSideBar_edge__j1N0v {
  width: 19vw;
  height: 50vh;
  border-radius: 25px;
  padding: 15px;
  margin: 0 3vw;
  margin-top: 6vh;
  background-color: rgb(238, 241, 238);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.RightSideBar_sideBar__YfbYj {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.RightSideBar_sideBar__YfbYj * {
  text-align: center;
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/RightSideBar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,eAAe;EACf,oCAAoC;EACpC,wEAAwE;AAC1E;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".edge {\n  width: 19vw;\n  height: 50vh;\n  border-radius: 25px;\n  padding: 15px;\n  margin: 0 3vw;\n  margin-top: 6vh;\n  background-color: rgb(238, 241, 238);\n  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);\n}\n\n.sideBar {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n}\n\n.sideBar * {\n  text-align: center;\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edge": `RightSideBar_edge__j1N0v`,
	"sideBar": `RightSideBar_sideBar__YfbYj`
};
export default ___CSS_LOADER_EXPORT___;
