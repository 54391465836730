import { useRef, useState } from "react";
import personIcon from "../assets/person.png";
import closeIcon from "../assets/icon--close-button.svg";
import styles from "./UserMenu.module.css";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
const bindCN = classNames.bind(styles);

function UserMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const modalBackground = useRef();

  return (
    <>
      <button className={styles.iconButton} onClick={() => setIsOpen(true)}>
        <img src={personIcon} alt="유저 메뉴" />
      </button>
      {isOpen && (
        <div
          className={styles.modalContainer}
          ref={modalBackground}
          onClick={(e) => {
            if (e.target === modalBackground.current) {
              setIsOpen(false);
            }
          }}
        >
          <div className={styles.modalContent}>
            <div className={styles.modalHead}>
              <p className={styles.login}>로그인</p>
              <div
                className={styles.closePopup}
                onClick={() => setIsOpen(false)}
              >
                <img src={closeIcon} alt="닫기" />
              </div>
            </div>
            <div className={styles.modalBody}>
              <p>로그인하면 더 많은 기능을 이용하실 수 있어요!</p>
              <form method="post" className={styles.formContainer}>
                <div className={bindCN(styles.inputContainer, styles.email)}>
                  <label>
                    <div className={styles.inputLabelContainer}>
                      <div className={styles.inputLabel}>이메일</div>
                    </div>
                    <input
                      type="text"
                      name="email"
                      inputMode="email"
                      autoComplete="off"
                      className={styles.input}
                    />
                  </label>
                </div>
                <div className={bindCN(styles.inputContainer, styles.password)}>
                  <label>
                    <div className={styles.inputLabelContainer}>
                      <div className={styles.inputLabel}>비밀번호</div>
                    </div>
                    <input
                      type="password"
                      name="password"
                      autoComplete="off"
                      className={styles.input}
                    />
                  </label>
                </div>
                <div>
                  <button type="submit" className={styles.submitButton}>
                    <div className={styles.submitLabel}>로그인 하기</div>
                  </button>
                </div>
              </form>
              <div className={styles.signInMenuContainer}>
                <div className={styles.oauthSignInContainer}>
                  <p>SNS 간편 로그인 / 가입</p>
                  <div
                    className={bindCN(styles.iconContainer, styles.google)}
                  ></div>
                  <div
                    className={bindCN(styles.iconContainer, styles.kakao)}
                  ></div>
                </div>
                <div className={styles.alignRight}>
                  <Link to={"/signin"}>
                    <p>이메일로 가입하기</p>
                  </Link>
                </div>
                <div className={styles.alignRight}>
                  <Link to={"/forgotpassword"}>
                    <p>비밀번호 찾기</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UserMenu;
