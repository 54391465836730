import React, { useState, useRef, useEffect } from "react";
import styles from "./CouncelPage.module.css";
import classNames from "classnames/bind";
const bindCN = classNames.bind(styles);

const ChatbotMessage = ({ message, isUser }) => (
  <div
    className={bindCN(
      styles.messageContainer,
      styles[`${isUser ? "userMessage" : "botMessage"}`]
    )}
  >
    <div className={styles.messageContent}>{message}</div>
  </div>
);

const CommunityChatbot = ({ onConnectToAgent }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    setMessages([
      {
        text: "안녕하세요! 커뮤니티 이용약관 AI 상담원입니다. 무엇을 도와드릴까요? 'help'를 입력하시면 도움말을 볼 수 있습니다.",
        isUser: false,
      },
    ]);
  }, []);

  const getHelpMessage = () => {
    return `커뮤니티 이용약관 주요 내용:

1. 목적: 커뮤니티 서비스 이용에 관한 권리, 의무 및 책임사항 규정
2. 서비스 이용 제한: 서비스 이용 제한 조건 및 절차
3. 회원가입: 가입 절차 및 제한 사항
4. 회원의 의무: 정보 도용 금지, 불건전 내용 게시 금지 등
5. 콘텐츠 관리: 부적절한 콘텐츠 관리 정책
6. 제재 정책: 위반 행위에 대한 제재 조치
7. 개인정보 보호: 개인정보 처리 방침
8. 서비스 변경 및 중단: 서비스 변경 및 중단 조건
9. 게시물 관리: 부적절한 게시물 관리 정책
10. 저작권 및 지적재산권: 게시물의 저작권 및 지적재산권 관련 규정
11. 닉네임 변경: 닉네임 변경 규정 및 제한 사항

자세한 내용은 각 항목 번호를 입력하시거나 '상담원연결'을 입력하세요.`;
  };

  const getChatbotResponse = (userInput) => {
    const lowerInput = userInput.toLowerCase();
    if (lowerInput === "help") {
      return getHelpMessage();
    }
    if (
      lowerInput === "상담원연결" ||
      lowerInput.includes("상담원") ||
      lowerInput.includes("상담원 연결")
    ) {
      onConnectToAgent();
      return "상담원과 연결합니다. 잠시만 기다려 주세요.";
    }
    switch (lowerInput) {
      case "1":
        return "이용약관의 목적은 커뮤니티 서비스 이용에 관한 권리, 의무 및 책임사항을 규정하는 것입니다.";
      case "2":
        return "서비스 이용 제한: 커뮤니티는 회원이 이용약관을 위반하거나 서비스의 정상적인 운영을 방해한 경우 서비스 이용을 제한할 수 있습니다. 제한 조치 전 회원에게 사전 통지하며, 긴급한 경우 선 조치 후 사후 통지할 수 있습니다.";
      case "3":
        return "회원가입은 커뮤니티가 정한 가입 양식에 따라 정보를 기입하고 약관에 동의함으로써 신청할 수 있습니다. 허위 정보 제공 시 서비스 이용이 제한될 수 있습니다.";
      case "4":
        return "회원의 주요 의무: 타인의 정보 도용 금지, 커뮤니티 게시 정보 무단 변경 금지, 저작권 침해 금지, 불건전한 내용 게시 금지 등이 있습니다.";
      case "5":
        return "콘텐츠 관리: 부적절한 콘텐츠(욕설, 비방, 광고 등)는 삭제될 수 있으며, 저작권 침해, 개인정보 노출 등도 제한됩니다.";
      case "6":
        return "제재 정책: 위반 행위에 따라 경고, 일시적 이용 정지, 영구적 이용 제한 등의 제재가 적용될 수 있습니다. 심각한 위반 시 계정 삭제 및 법적 조치가 취해질 수 있습니다.";
      case "7":
        return "커뮤니티는 회원의 개인정보를 보호하기 위해 노력하며, 관련 법령 및 개인정보처리방침을 준수합니다.";
      case "8":
        return "서비스 변경 및 중단: 운영상, 기술상 필요에 따라 서비스를 변경할 수 있으며, 불가항력적 사유로 서비스가 중단될 수 있습니다.";
      case "9":
        return "게시물 관리: 커뮤니티는 불건전하거나 부적절한 게시물을 삭제하거나 이동시킬 수 있습니다.";
      case "10":
        return "저작권 및 지적재산권: 회원이 커뮤니티 내에 게시한 게시물의 저작권은 해당 회원에게 귀속됩니다. 다만, 커뮤니티는 서비스 내에서 게시물을 이용, 편집, 복제, 전송할 수 있는 권한을 가지며, 홍보 등의 목적으로 게시물을 활용할 수 있습니다.";
      case "11":
        return "닉네임 변경: 닉네임은 다른 회원과 중복되지 않아야 하며, 1회 설정 시 한 달간 변경이 불가능합니다. 욕설 등 부적절한 닉네임은 사용이 제한될 수 있습니다.";
      default:
        return "죄송합니다. 질문을 이해하지 못했습니다. 'help'를 입력하시면 도움말을 보여드립니다.";
    }
  };

  const handleSend = () => {
    if (input.trim() === "") return;
    setMessages([...messages, { text: input, isUser: true }]);
    setInput("");
    const response = getChatbotResponse(input);
    setTimeout(() => {
      setMessages((msgs) => [...msgs, { text: response, isUser: false }]);
    }, 500);
  };

  return (
    <div className={styles.chatbotContainer}>
      <div className={styles.chatbotHeader}>
        <h1 className={styles.chatbotTitle}>커뮤니티 이용약관 AI 상담원</h1>
      </div>
      <div className={styles.messagesContainer}>
        {messages.map((message, index) => (
          <ChatbotMessage
            key={index}
            message={message.text}
            isUser={message.isUser}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className={styles.inputContainer}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSend()}
          placeholder="메시지를 입력하세요... (help 입력 시 도움말)"
          className={styles.inputField}
        />
        <button onClick={handleSend} className={styles.sendButton}>
          전송
        </button>
      </div>
    </div>
  );
};

const AgentChatWindow = ({ onClose }) => {
  return (
    <div className={styles.agentChatContainer}>
      <div className={styles.agentChatHeader}>
        <h2 className={styles.agentChatTitle}>상담원 채팅</h2>
        <button onClick={onClose} className={styles.closeButton}>
          닫기
        </button>
      </div>
      <div className={styles.agentChatBody}>
        <p>상담원과 연결되었습니다. 어떤 도움이 필요하신가요?</p>
        {/* 여기에 실제 상담원 채팅 인터페이스를 구현합니다 */}
      </div>
    </div>
  );
};

function CouncelPage() {
  const [showAgentChat, setShowAgentChat] = useState(false);

  const handleConnectToAgent = () => {
    setShowAgentChat(true);
  };

  return (
    <div className={styles.chatContainer}>
      {showAgentChat ? (
        <AgentChatWindow onClose={() => setShowAgentChat(false)} />
      ) : (
        <CommunityChatbot onConnectToAgent={handleConnectToAgent} />
      )}
    </div>
  );
}

export default CouncelPage;
