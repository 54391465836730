import pf from "../assets/logo.svg";
import pfbg from "../assets/backgroundWhite.png";
import styles from "./ShowPageProfile.module.css";

function ShowPageProfile({ title, pfbgImg = pfbg, pfImg = pf }) {
  return (
    <div
      alt="프로필 배경 이미지"
      style={{
        backgroundImage: `url(${pfbgImg})`,
      }}
      className={styles.background}
    >
      <img src={pfImg} alt="프로필 이미지" className={styles.pfImg} />
      <span className={styles.name}>{title}</span>
    </div>
  );
}

export default ShowPageProfile;
