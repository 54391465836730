// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Nav_nav__iMr2i {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 15px 0;
  background-color: #fff;
  margin: 0 15px;
  border-bottom: var(--border-line);
}

.Nav_container__omOm2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Nav_image__6m4bQ {
  height: 40px;
}

.Nav_searchBar__gw43u {
  position: relative;
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/Nav.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,MAAM;EACN,UAAU;EACV,eAAe;EACf,sBAAsB;EACtB,cAAc;EACd,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".nav {\n  position: sticky;\n  top: 0;\n  z-index: 1;\n  padding: 15px 0;\n  background-color: #fff;\n  margin: 0 15px;\n  border-bottom: var(--border-line);\n}\n\n.container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.image {\n  height: 40px;\n}\n\n.searchBar {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": `Nav_nav__iMr2i`,
	"container": `Nav_container__omOm2`,
	"image": `Nav_image__6m4bQ`,
	"searchBar": `Nav_searchBar__gw43u`
};
export default ___CSS_LOADER_EXPORT___;
