// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFoundPage_link__kZuZL {
  width: 64vw;
  margin: 30px 50px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/NotFoundPage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".link {\n  width: 64vw;\n  margin: 30px 50px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `NotFoundPage_link__kZuZL`
};
export default ___CSS_LOADER_EXPORT___;
