import { useNavigate } from "react-router-dom";
import { getCoumminityNames } from "../api";
import styles from "./AutoComplete.module.css";

function AutoComplete({ keyword, aboutHistory }) {
  const { history, editSearchHistory } = aboutHistory;
  const communities = getCoumminityNames(keyword);
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    const slug = e.target.id;
    const title = e.target.textContent;
    document.getElementById("searchInput").blur();
    editSearchHistory("add", title);
    console.log(title);
    navigate(`/nest/${slug}`);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    editSearchHistory("delete", e.target.name);
  };
  return (
    <div className={styles.list}>
      {keyword ? (
        <>
          {communities.map(({ id, title, slug }) => (
            <div key={id}>
              <h4
                onMouseDown={handleClick}
                id={slug}
                className={styles.element}
              >
                {title}
              </h4>
            </div>
          ))}
        </>
      ) : (
        <>
          {history.slice(0, 5).map((record, i) => (
            <div key={i} id={record}>
              <h4 onMouseDown={handleClick} className={styles.element}>
                {record}
              </h4>
              <button onMouseDown={handleDelete} name={record} type="button">
                x
              </button>
            </div>
          ))}
        </>
      )}
      <div style={{ paddingLeft: "15px" }}>
        <p>인기 급상승 커뮤니티</p>
      </div>
    </div>
  );
}

export default AutoComplete;
