import { Link } from "react-router-dom";
import styles from "./RightSideBar.module.css";

function RightSideBar() {
  return (
    <div className={styles.edge}>
      <div className={styles.sideBar}>
        <Link to={"/calender"}>
          <p>주식 캘린더</p>
        </Link>
        <Link to={"/calender"}>
          <p>주식 캘린더</p>
        </Link>
        <Link to={"/calender"}>
          <p>주식 캘린더</p>
        </Link>
        <Link to={"/councel"}>
          <p>챗봇 상담</p>
        </Link>
      </div>
    </div>
  );
}

export default RightSideBar;
