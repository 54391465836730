// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Container_container__1Ti4x {
  width: calc(100vw - 50px);
  margin: 0;
}

@media (max-width: 840px) {
  .Container_container__1Ti4x {
    padding: 0 calc(14px * 5);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Container.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,SAAS;AACX;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".container {\n  width: calc(100vw - 50px);\n  margin: 0;\n}\n\n@media (max-width: 840px) {\n  .container {\n    padding: 0 calc(14px * 5);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Container_container__1Ti4x`
};
export default ___CSS_LOADER_EXPORT___;
