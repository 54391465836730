// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AutoComplete_list__0WiN8 {
  margin: 0;
  padding-top: 40px;
  list-style-type: none;
  background-color: rgb(255, 255, 255);
  width: calc(40vw + 44px);
  border-radius: 20px;
  box-shadow: 0 0 20px 0 rgba(200, 200, 200, 0.622);
}

.AutoComplete_element__hXRbo {
  cursor: pointer;
  padding-left: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AutoComplete.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,iBAAiB;EACjB,qBAAqB;EACrB,oCAAoC;EACpC,wBAAwB;EACxB,mBAAmB;EACnB,iDAAiD;AACnD;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".list {\n  margin: 0;\n  padding-top: 40px;\n  list-style-type: none;\n  background-color: rgb(255, 255, 255);\n  width: calc(40vw + 44px);\n  border-radius: 20px;\n  box-shadow: 0 0 20px 0 rgba(200, 200, 200, 0.622);\n}\n\n.element {\n  cursor: pointer;\n  padding-left: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `AutoComplete_list__0WiN8`,
	"element": `AutoComplete_element__hXRbo`
};
export default ___CSS_LOADER_EXPORT___;
