import Nav from "./components/Nav";
import styles from "./components/App.module.css";
import "./components/App.font.css";
import { Outlet } from "react-router-dom";
import LeftSideBar from "./components/LeftSideBar";
import RightSideBar from "./components/RightSideBar";
import { SearchProvider } from "./contexts/SearchContext";

function App() {
  return (
    <div>
      <SearchProvider>
        <Nav className={styles.nav} />
        <div className={styles.body}>
          <LeftSideBar />
          <div className={styles.content}>
            <Outlet />
          </div>
          <RightSideBar />
        </div>
      </SearchProvider>
    </div>
  );
}

export default App;
