// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchBar_searchBar__xUq3F {
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
}

.SearchBar_form__ngDTv {
  border-radius: 20px 20px 0 0;
  display: flex;
  height: 40px;
}

.SearchBar_form__ngDTv input {
  flex: 1 1;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  outline: none;
  box-sizing: border-box;
  width: 40vw;
}

.SearchBar_form__ngDTv input::placeholder {
  color: #2f2f2f;
}

.SearchBar_form__ngDTv input:focus::placeholder {
  color: transparent;
}

.SearchBar_blurredInput__ilwsP {
  background-color: rgb(240, 240, 240);
  border-radius: 35px 0 0 35px;
}

.SearchBar_blurredInput__ilwsP:hover {
  background-color: rgb(231, 237, 238);
}

.SearchBar_focusedInput__XUx\\+U {
  background-color: rgb(255, 255, 255);
  border-radius: 20px 0 0 0;
}

.SearchBar_form__ngDTv button[type="submit"] {
  flex: none;
  padding: 8px 10px;
  color: #ffffff;
  cursor: pointer;
  background-color: #dbdbdb;
  border: none;
  outline: none;
}

.SearchBar_blurredButton__hRDGQ {
  border-radius: 0 35px 35px 0;
}

.SearchBar_focusedButton__z9aQu {
  border-radius: 0 20px 0 0;
}

.SearchBar_form__ngDTv button[type="submit"] img {
  width: 24px;
  height: 24px;
}

.SearchBar_autoCompleteContainer__5BmhR {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
}
`, "",{"version":3,"sources":["webpack://./src/components/SearchBar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;EAC5B,aAAa;EACb,YAAY;AACd;;AAEA;EACE,SAAS;EACT,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;EACpC,4BAA4B;AAC9B;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;EACpC,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;EACf,MAAM;EACN,OAAO;EACP,uBAAuB;AACzB","sourcesContent":[".searchBar {\n  display: flex;\n  flex-direction: column;\n  border-radius: 20px 20px 0 0;\n}\n\n.form {\n  border-radius: 20px 20px 0 0;\n  display: flex;\n  height: 40px;\n}\n\n.form input {\n  flex: 1 1;\n  padding: 12px 20px;\n  font-size: 14px;\n  font-weight: 500;\n  border: none;\n  outline: none;\n  box-sizing: border-box;\n  width: 40vw;\n}\n\n.form input::placeholder {\n  color: #2f2f2f;\n}\n\n.form input:focus::placeholder {\n  color: transparent;\n}\n\n.blurredInput {\n  background-color: rgb(240, 240, 240);\n  border-radius: 35px 0 0 35px;\n}\n\n.blurredInput:hover {\n  background-color: rgb(231, 237, 238);\n}\n\n.focusedInput {\n  background-color: rgb(255, 255, 255);\n  border-radius: 20px 0 0 0;\n}\n\n.form button[type=\"submit\"] {\n  flex: none;\n  padding: 8px 10px;\n  color: #ffffff;\n  cursor: pointer;\n  background-color: #dbdbdb;\n  border: none;\n  outline: none;\n}\n\n.blurredButton {\n  border-radius: 0 35px 35px 0;\n}\n\n.focusedButton {\n  border-radius: 0 20px 0 0;\n}\n\n.form button[type=\"submit\"] img {\n  width: 24px;\n  height: 24px;\n}\n\n.autoCompleteContainer {\n  width: 100vw;\n  height: 100vh;\n  position: fixed;\n  top: 0;\n  left: 0;\n  background-color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBar": `SearchBar_searchBar__xUq3F`,
	"form": `SearchBar_form__ngDTv`,
	"blurredInput": `SearchBar_blurredInput__ilwsP`,
	"focusedInput": `SearchBar_focusedInput__XUx+U`,
	"blurredButton": `SearchBar_blurredButton__hRDGQ`,
	"focusedButton": `SearchBar_focusedButton__z9aQu`,
	"autoCompleteContainer": `SearchBar_autoCompleteContainer__5BmhR`
};
export default ___CSS_LOADER_EXPORT___;
